import React, { useState } from "react";
import "./style.scss";
import WsIcon from "../../assets/images/ws2.png";
import TgIcon from "../../assets/images/tg.png";
import ViberIcon from "../../assets/images/viber.png";
import InstIcon from "../../assets/images/inst.webp";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  summary: {
    backgroundColor: "gray",
  },
});
const AppFooter = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);

  const [isExpanded5, setIsExpanded5] = useState(false);
  const [isExpanded6, setIsExpanded6] = useState(false);
  const [isExpanded7, setIsExpanded7] = useState(false);
  const [isExpanded8, setIsExpanded8] = useState(false);
  const [isExpanded9, setIsExpanded9] = useState(false);
  return (
    <footer className="footer">
      <div className="footer-column">
        <p className="footer-brand">
          Сервис центр замков Москва, Московская область
        </p>
        <ul className="footer-phone">
          <li>
            <a href="tel:+79687110240">+7(968)711-02-40</a>
          </li>
          <li>
            <a href="tel:+79017929221">+7(901)792-92-21</a>
            <a href="https://wa.me/79017929221">
              <img src={WsIcon} alt="Whatsapp" />
            </a>
            <a href="https://t.me/moscow_lockru">
              <img src={TgIcon} />
            </a>
            <a href="viber://chat?number=%2B79017929221">
              <img src={ViberIcon} alt="Viber" />
            </a>
            <a href="https://www.instagram.com/moscow_lock.ru">
              <img src={InstIcon} alt="Instagram" />
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-column footer-regions">
        <Accordion
          expanded={isExpanded}
          onChange={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>ВАО</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Восточный административный округ</li>
              <li>Богородское</li>
              <li>Вешняки</li>
              <li>Восточный</li>
              <li>Гольяново</li>
              <li>Ивановский</li>
              <li>Измайлово</li>
              <li>Косино-Ухтомский</li>
              <li>Кожухово</li>
              <li>Метрогородок</li>
              <li>Новогиреево</li>
              <li>Новокосино</li>
              <li>Перово</li>
              <li>Преображенский</li>
              <li>Соколиная гора</li>
              <li>Сокольники</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={isExpanded1}
          onChange={() => {
            setIsExpanded1(!isExpanded1);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>СВАО</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Северо-Восточный административный округ</li>
              <li>Алексеевский</li>
              <li>Алтуфьевский</li>
              <li>Бабушкинский</li>
              <li>Бибирево</li>
              <li>Бутырский</li>
              <li>Лианозово</li>
              <li>Марфино</li>
              <li>Марьина Роща</li>
              <li>Останкинскинский</li>
              <li>Отрадное</li>
              <li>Ростокино</li>
              <li>Свиблово</li>
              <li>Медведково</li>
              <li>Северный</li>
              <li>Ярославский</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={isExpanded2}
          onChange={() => {
            setIsExpanded2(!isExpanded2);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>ЮВАО</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Юго-Восточный административный округ</li>
              <li>Выхино-Жулебино</li>
              <li>Капотня</li>
              <li>Кузьминки</li>
              <li>Лефортово</li>
              <li>Люблино</li>
              <li>Марьино</li>
              <li>Некрасовка</li>
              <li>Нижегородский</li>
              <li>Печатники</li>
              <li>Рязанский</li>
              <li>Текстильщики</li>
              <li>Южнопортовый</li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={isExpanded3}
          onChange={() => {
            setIsExpanded3(!isExpanded3);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>ЗАО</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Западный административный округ</li>
              <li>Внуково</li>
              <li>Дорогомилово</li>
              <li>Крылатское</li>
              <li>Кунцево</li>
              <li>Можайский</li>
              <li>Ново-Переделкино</li>
              <li>Очаково-Матвеевское</li>
              <li>Проспект Вернадского</li>
              <li>Раменки</li>
              <li>Солнцево</li>
              <li>Тропарево–Никулино</li>
              <li>Филевский Парк</li>
              <li>Фили-Давыдково</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={isExpanded4}
          onChange={() => {
            setIsExpanded4(!isExpanded4);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>СЗАО</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Северо-Западный административный округ</li>
              <li>Куркино</li>
              <li>Митино</li>
              <li>Покровское-Стрешнево</li>
              <li>Строгино</li>
              <li>Тушино</li>
              <li>Хорошево-Мневники</li>
              <li>Щукино</li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="footer-column">
        <Accordion
          expanded={isExpanded5}
          onChange={() => {
            setIsExpanded5(!isExpanded5);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>ЦАО</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Центральный административный округ</li>
              <li>Арбат</li>
              <li>Басманный</li>
              <li>Замоскворечье</li>
              <li>Красносельский</li>
              <li>Мещанский</li>
              <li>Пресненский</li>
              <li>Таганский</li>
              <li>Тверской</li>
              <li>Хамовники</li>
              <li>Якиманка</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={isExpanded6}
          onChange={() => {
            setIsExpanded6(!isExpanded6);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>ЮАО</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Южный административный округ</li>
              <li>Бирюлево</li>
              <li>Братеево</li>
              <li>Даниловский</li>
              <li>Донской</li>
              <li>Зябликово</li>
              <li>Москворечье-Сабурово</li>
              <li>Нагатино-Садовники</li>
              <li>Нагатинский Затон</li>
              <li>Нагорный</li>
              <li>Орехово-Борисово</li>
              <li>Царицыно</li>
              <li>Чертаново</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={isExpanded7}
          onChange={() => {
            setIsExpanded7(!isExpanded7);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>САО</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Северный административный округ</li>
              <li>Аэропорт</li>
              <li>Беговой</li>
              <li>Бескудниковский</li>
              <li>Войковский</li>
              <li>Головинский</li>
              <li>Дегунино</li>
              <li>Дмитровский</li>
              <li>Коптево</li>
              <li>Левобережный</li>
              <li>Молжаниновский</li>
              <li>Савёловский</li>
              <li>Сокол</li>
              <li>Тимирязевский</li>
              <li>Ховрино</li>
              <li>Хорошёвский</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={isExpanded9}
          onChange={() => {
            setIsExpanded9(!isExpanded9);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>ЮЗАО</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Юго-Западный административный округ</li>
              <li>Академический</li>
              <li>Гагаринский</li>
              <li>Зюзино</li>
              <li>Коньково</li>
              <li>Котловка</li>
              <li>Ломоносовский</li>
              <li>Обручевский</li>
              <li>Теплый Стан</li>
              <li>Черемушки</li>
              <li>Южное Бутово</li>
              <li>Ясенево</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={isExpanded8}
          onChange={() => {
            setIsExpanded8(!isExpanded8);
          }}
        >
          <AccordionSummary
            className="my-accordion-footer"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>Московская область</h3>
          </AccordionSummary>
          <AccordionDetails className="my-accordion-footer">
            <ul>
              <li>Видное</li>
              <li>Дзержинский</li>
              <li>Домодедово</li>
              <li>Железнодорожный</li>
              <li>Жуковский</li>
              <li>Королёв</li>
              <li>Котельники</li>
              <li>Красногорск</li>
              <li>Лыткарино</li>
              <li>Люберцы</li>
              <li>Мытищи</li>
              <li>Реутов</li>
              <li>Химки</li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
    </footer>
  );
};

export default AppFooter;
